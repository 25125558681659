<template>
  <b-card>
    <ModalProjectCosts :editable="editable" :cost_id="$route.params.id" />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import ModalProjectCosts from './ModalProjectCosts.vue';
export default {
  components: {
    BCard,
    ModalProjectCosts,
  },
  data() {
    return {
      editable: true,
    };
  },
};
</script>
